import { createAsyncThunk } from "@reduxjs/toolkit";


import { deleteDraft, getDraft, getDrafts, getMessageTypes, saveDraft } from "../../api/api";
import { RootState } from "src";
import { MessageDraft, MessageDraftRequest } from "src/util/types";

export const fetchDraftComms = createAsyncThunk(
  "draftComms/fetchDraftComms",
  async (_, {  getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const { contractSelected } = state.login;

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');
      
      return await getDrafts(contractSelected.id);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const addDraftComm = createAsyncThunk(
  "draftComms/addDraftComm",
  async (draftComm: MessageDraft, { getState, rejectWithValue }) => {
    try {     
      const state = getState() as RootState;
      const { contractSelected } = state.login;

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');

      const saveDraftRequest: MessageDraftRequest = {
        ...draftComm,
        contractid: contractSelected.id
      }; 
      await saveDraft(saveDraftRequest);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const getDraftById = createAsyncThunk(
  "draftComms/getDraftById",
  async (id: string, { rejectWithValue }) => {
    try {
      return await getDraft(id)
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
)


export const updateDraftComm = createAsyncThunk(
  "draftComms/updateDraftComm",
  async (draftComm: MessageDraft, { getState ,rejectWithValue }) => {
    try {
      //enviar id 
      const state = getState() as RootState;
      const { contractSelected } = state.login;

      if( !contractSelected ) return rejectWithValue('No se ha seleccionado un contrato.');

      const saveDraftRequest: MessageDraftRequest = {
        ...draftComm,
        parentid: null,
        contractid: contractSelected.id
      }; 
      return await saveDraft(saveDraftRequest);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDraftComm = createAsyncThunk(
  "draftComms/deleteDraftComm",
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteDraft(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchMessageTypes = createAsyncThunk(
  "messageTypes/fetchMessageTypes",
  async (_, { rejectWithValue }) => {
    try {
      return await getMessageTypes();
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

