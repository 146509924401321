import { Button, Collapse, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import DOMPurify from 'dompurify';
import React, { useEffect, useState } from 'react';

import { Attachment, Message } from 'src/util/types';
import { downloadAttachment } from 'src/slices/Communications/thunks';
import { downloadMessagePdf } from 'src/api/api';
import { isoToDateYear, isoToLongDate } from 'src/util/date';
import { toastError, toastSuccess } from 'src/util/toast';
import { useAppDispatch } from 'src/hooks/useAppDispatch';



export const CommDetail: React.FC<{selectedComm: Message, isResponse: boolean, sequence?: string}> = ({selectedComm, isResponse, sequence}) => {
  const dispatch = useAppDispatch();

  const [openComm, setOpenComm] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    if(!isResponse){
      setOpenComm(true);
    }
    if(isResponse && !selectedComm.read) {
      setOpenComm(true);
    }
  }, [])

  const title = isResponse ? `RESPUESTA ${sequence ?? ''}` : `${selectedComm.type?.toUpperCase()} ${selectedComm.sequence ?? ''}`;

  const toggleComm = () => {
    setOpenComm(!openComm);
  }

  const sanitizedHTML = (html: string) => {
    return DOMPurify.sanitize(html);
  }

  const downloadPdf = async () => {
    setIsDownloading(true);
    const url = await downloadMessagePdf(selectedComm.id)
    setIsDownloading(false);
    window.location.href = url;
  }

  const downloadAttachments = async (id: string) => {

    const action = await dispatch(downloadAttachment(id));
  
    if (downloadAttachment.fulfilled.match(action)) {
      toastSuccess('Archivo descargado correctamente');
      window.location.href = action.payload ?? '';
    } else if (downloadAttachment.rejected.match(action)) {
      toastError('Error al descargar el archivo');
    }

  };

  return (
    <div className="accordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !openComm }
            )}
            type="button"
            onClick={toggleComm}
            style={{ cursor: "pointer" }}
          >
            <div className="flex-1">
              <h5 className="font-size-14 my-1">
                {title} / {isoToDateYear(selectedComm.date)}
              </h5>
            </div>
          </button>
        </h2>
        <Collapse isOpen={openComm}>
          <div className="p-3" style={{ color: "black" }}>
            <h4 className='text-center mt-2'>{title} / {isoToDateYear(selectedComm.date)}</h4>
            
            <p className="text-end">La Reina, {isoToLongDate(selectedComm.date)}</p>
            
            <p><strong>Contrato:</strong> {selectedComm.contract}</p>
            <p><strong>De:</strong> {selectedComm.source}</p>
            <p><strong>Para:</strong> {selectedComm.destination}</p>

            <p className='mt-2' dangerouslySetInnerHTML={{ __html: sanitizedHTML(selectedComm.body) }}></p>

            <hr className='mt-5'></hr>
                <h5 className="mb-3">Archivos Adjuntos</h5>
                {selectedComm.attachments.length > 0 ? (
                  <ListGroup flush>
                    {selectedComm.attachments.map((file) => (
                      <ListGroupItem key={(file as Attachment).id} className="">
                        <Link to="#" onClick={() => downloadAttachments((file as Attachment).id)}>
                        {file.filename}
                        </Link>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                ) : (
                  <p className="text-muted">No hay archivos adjuntos.</p>
                )}
            <Button
              onClick={downloadPdf}
              className='mt-4'
              disabled={isDownloading}
              type="button"
              color='dark'
              outline
            >
              {
                isDownloading ?
                  <Spinner color="dark" size="sm" ></Spinner>
                  :
                  <><i className="mdi mdi-file-pdf-outline"></i> Descargar PDF</>
              }
            </Button>
          </div>

        </Collapse>
      </div>
    </div>
  )
}
