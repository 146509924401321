
export interface TempFileUpload {
  url: string;
  file: string;
  uploadId: string;
}

export interface User {
  id:           string;
  email:        string;
  name:         string;
  contracts:    Contract[];
  tokenVersion: number;
  token:        string;
  profile:      string;
  hasCertificate: boolean;
  hasRubric:      boolean;
}
export enum ActionState {
  NOT_STARTED,
  SUCCESS,
  ERROR,
  LOADING
}

export {ActionState as CommActionState }
export {ActionState as FileActionState }
export {ActionState as LoginActionState }

export interface Contract {
  id:   string;
  name: string;
}

export interface SubirArchivo {
  nombre: string;
  nombre_documento: string;
  descripcion: string;
  tipo: string; // Ejemplo: decreto
  fecha_emision: string | null;
  mime_type: string;
  contractid: string;
}

export interface Archivo extends SubirArchivo {
  id: string;
  fecha_incorporacion: string;
}

export interface EditarArchivo extends Omit<SubirArchivo, 'mime_type' | 'nombre_documento' | 'fecha_incorporacion'>{
  id: string;
}

export interface RequestTempFileUploadResponse {
  fileName: string;
  url: string;
}

export interface ContractIdParams {
  contractid: string;
}

export interface MessageType {
  id: string;
  name: string;
  requiresresponse: boolean;
  requiresdeadline: boolean | null;
  color: string;
}

export interface TempAttachment {
  tempFile: string;
  filename: string;
  uploadId: string;
  hash: string;
}

export interface Attachment {
  id: string;
  filename: string;
}

export interface MessageDraft
{
  body: string;
  typeid?: string;
  deadline: string | null;
  attachments: (TempAttachment | Attachment)[] ;
  id?: string;
  parentid: string | null;
}

export interface MessageDraftRequest extends MessageDraft
{
  contractid: string | null;
  parentid: string | null;
}

export interface MessageSummary
{
  id: string;
  date: string;
  author: string;
  badge:
  {
    color: string;
    name: string;
  };
  icon: string;
  bodysummary: string;
  replies: number;
  read: boolean;
  sequence: string;
  parentid: string | null;
}

export interface Message extends MessageDraft
{
  id: string;
  type?: string;
  author: {
    name: string;
    email: string;
  },
  parentid: string | null;
  date: string;
  read: boolean;
  replies: Message[];
  sequence: string;
  contract: string;
  source: string;
  sourceid: string;
  destination: string;
}

export interface FormValues {
  commType: string;
  editorContent: string;
  deadline: string;
  attachedFiles: (TempAttachment | Attachment)[];
  mustRespond: boolean;
}

export interface UploadImageResult {
  data: {
    link: string | ArrayBuffer | null;
  };
}

export enum FormAction {
  EMIT,
  SAVE_DRAFT,
  DELETE_DRAFT
}

export enum UploadFlow {
  CERTIFICATE = 1,
  RUBRIC
}
