import { EditorState } from 'draft-js';
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { Card, Col, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import { FormikProps } from "formik";

import { Attachment, FormValues, TempAttachment } from "src/util/types";
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { fetchMessageTypes } from 'src/slices/Draft Communications/thunks';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { isoToFormDateTime } from 'src/util/date';
import Dropzone from 'react-dropzone';
import { addTempAttachments } from 'src/slices/Communications/thunks';
import DatePicker from 'react-datepicker';

interface CommunicationFormProps {
  formik: FormikProps<FormValues>; 
  editorState: EditorState; 
  handleEditorChange: (state: EditorState) => void;
  uploadImageCallback: (file: File) => Promise<any>;
  onSubmit: () => void;
  handleFilesUpload: (value: boolean) => void;
  isLoadingFiles: boolean;
  isResponse: boolean;
}

const CommunicationForm: React.FC<CommunicationFormProps> = ({
  editorState,
  handleEditorChange,
  uploadImageCallback,
  onSubmit,
  formik,
  handleFilesUpload,
  isLoadingFiles,
  isResponse
}) => {
  
  const { messageTypes } = useSelector((state: RootState) => state.draftComms)

  const dispatch = useAppDispatch();

  const [error, setError] = useState<string | null>(null);
  const [deadlineNotApply, setDeadlineNotApply] = useState(false)


  useEffect( () => {
    dispatch(fetchMessageTypes())

    if(formik.values.attachedFiles?.length) {
      setSelectedFiles(c => [...c, ...formik.values.attachedFiles]);
    }
  }, [])

  useEffect(() => {
    const messageType = messageTypes.find(m => m.id === formik.values.commType)
    setDeadlineNotApply(messageType?.requiresdeadline === null);

  }, [formik.values]) 
  
  const handleAcceptedFiles = async (files: File[]) => {
    setError(null); 
    handleFilesUpload(true)
    try {
      const action = await dispatch(addTempAttachments(files));
  
      // Check if the thunk was rejected
      if (addTempAttachments.rejected.match(action)) {
        throw new Error(action.payload || 'Error al cargar los archivos');
      }
  
      // Access the payload if the thunk was successful
      if (addTempAttachments.fulfilled.match(action)) {
        const tempAttachments = action.payload;
        formik.setFieldValue("attachedFiles", formik.values.attachedFiles.concat(tempAttachments));

        setSelectedFiles( c => [...c, ...tempAttachments]);
        handleFilesUpload(false);
      }
    } catch (error: any) {
      handleFilesUpload(false);
      setError(error.message);
    }
  };
  
  const [selectedFiles, setSelectedFiles] = useState<(TempAttachment | Attachment)[]>([]);

  const handleRemoveFile = (fileIndex: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, index) => index !== fileIndex));
  };

  return (
    <Form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
      <Row className="my-4" hidden={isResponse}> {/* Asunto */}
        <Label htmlFor="file-name" className="col-sm-3 col-form-label">Tipo de comunicación</Label>
        <Col sm={6}>
          <Input
            name="commType"
            type="select"
            className="form-select"
            value={formik.values.commType}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            invalid={ !!(formik.touched.commType && formik.errors.commType) }
          >
            <option value="" label="Selecciona una opción" />
            {
              messageTypes.map((m) => (
                <option key={m.id} value={m.id}>{m.name}</option>
              ))
            }
          </Input>
          { 
            formik.touched.commType && formik.errors.commType &&
            <FormFeedback type="invalid"> {formik.errors.commType} </FormFeedback>
          }
        </Col>
      </Row>

      <Row className="my-4"> {/* Cuerpo */}
        <Label htmlFor="file-name" className="col-sm-3 col-form-label">Cuerpo</Label>
        <Col sm={6}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            toolbar={{
              options: ["inline", "blockType", "fontSize", "list", "textAlign", "colorPicker", "link", "image", "history"],
              image: {
                uploadEnabled: true,
                uploadCallback: uploadImageCallback,
                previewImage: true,
                alt: { present: true, mandatory: false },
              },
            }}
          />
          {formik.errors.editorContent && (
            <div className="text-danger">{formik.errors.editorContent}</div>
          )}
        </Col>
      </Row>

      <Row className="mb-4" hidden={isResponse}> {/* requiere respuesta? */}
        <Col sm={3}>
        </Col>
        <Col sm={6}>
          <div className="form-check">
            <Input
              name="mustRespond"
              type="checkbox"
              className="form-check-input"
              id="mustRespond"
              onChange={formik.handleChange}
              checked={formik.values.mustRespond}
              invalid={!!formik.errors.mustRespond}
              />
            <Label className="form-check-label" htmlFor="mustRespond">Requiere respuesta</Label>
            {
              formik.errors.mustRespond &&
              <FormFeedback type="invalid">
                {formik.errors.mustRespond}
              </FormFeedback>
            }
          </div>
        </Col>
      </Row>

      <Row className="my-4" hidden={isResponse || deadlineNotApply}> {/* Fecha límite */}
        <Label htmlFor="deadline" className="col-sm-3 col-form-label">Fecha límite</Label>
        <Col sm={6}>
          <DatePicker
            name="deadline"
            selected={formik.values.deadline}
            onChange={(date: any) => formik.setFieldValue("deadline", date)}
            onBlur={formik.handleBlur}
            dateFormat="dd/MM/yyyy"
            placeholderText="dd/mm/yyyy"
            className="form-control"
          />
          {
            formik.touched.deadline && formik.errors.deadline &&
            <FormFeedback type="invalid">
              {formik.errors.deadline}
            </FormFeedback>
          }
        </Col>
      </Row>
      <Row className="mb-4"> {/* Archivos adjuntos */}
        <Col sm={3}>
          <Label htmlFor="deadline" className="col-sm-3 col-form-label">Archivos adjuntos </Label>
        </Col>
        <Col sm={6}>
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles);
            }}
            multiple={true}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone">
                <div
                  className="dz-message needsclick"
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <div className="mb-3">
                    <i className="display-4 text-muted uil uil-cloud-upload"></i>
                  </div>
                  <h4>Arrastra los documentos aquí o haz click para buscar en tu equipo.</h4>
                </div>
              </div>
            )}
          </Dropzone>
          <div className="dropzone-previews mt-3" id="file-previews">
            {selectedFiles.map((f, i: number) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <i className="fa fa-file-alt" />
                      </Col>
                      <Col>
                        <p className="mb-0">
                          <strong>{f.filename}</strong>
                        </p>
                      </Col>
                      <Col className="col-auto">
                        <button
                          type="button"
                          className="btn btn-link text-danger"
                          onClick={() => handleRemoveFile(i)}
                        >
                          Eliminar
                        </button>
                      </Col>
                    </Row>
                  </div>
                </Card>
              );
            })}
          </div>
          {error && <div className="text-danger">Error cargando archivos adjuntos: {error}</div>}
          {isLoadingFiles &&<Spinner color="primary" className='mt-3' />}
        </Col>
      </Row>
    </Form>
  );
};

export default CommunicationForm;
