export interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    subItems ?: any;
    isHeader ?: boolean;
    newTab?: boolean;
    isVisible: boolean;
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Dashboards",
        isHeader : true,
        isVisible: true
    },
    {
        id: 2,
        label: "Documentos",
        icon: "folder",
        link: "/repositorio/general",
        isVisible: true
    },
    {
        id: 3,
        label: "Comunicaciones",
        icon: "mail",
        isVisible: true,
        subItems: [
            {
                id: 31,
                label: "General",
                icon: "inbox",
                link: "/comunicaciones/general",
                isVisible: true
            },
            {
                id: 32,
                label: "Borradores",
                icon: "edit",
                link: "/comunicaciones/borradores",
                isVisible: true
            }
        ]
    },
];

export { menuItems };
