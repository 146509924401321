import { toast, ToastOptions } from "react-toastify";

const TOAST_CONFIG: ToastOptions = {
  position: 'bottom-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export const toastSuccess = (message: string) => {
  toast.success(message, TOAST_CONFIG);
}

export const toastError = (message: string) => {
  toast.error(message, TOAST_CONFIG);
}