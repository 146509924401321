import {
  deleteFile as deleteFileAPI,
  getFiles as getFilesAPI,
  getFile as getFileAPI,
  requestTempFileUpload,
  updateFile as updateFileAPI,
  addFile as addFileAPI,
  uploadFile as uploadFileAPI
} from 'src/api/api';
import {
  doneEliminatingFile,
  errorEditingFile,
  errorEliminatingFile,
  setFiles,
  setSelectedFile,
  startEditingFile,
  startEliminatingFile,
  startLoadingFiles,
  successEditingFile,
  errorGettingFiles,
  startGettingSelectedFile,
  errorGettingSelectedFile
} from './reducer';
import { EditarArchivo, SubirArchivo } from 'src/util/types';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getFiles = (contractid: string) => {
  return async (dispatch: any) => {
    try {
      if(!contractid) throw new Error("Debe seleccionar un contrato");
      dispatch( startLoadingFiles() );
      const files = await getFilesAPI({ contractid });
      dispatch( setFiles({files}) )
    } catch (error) {
      dispatch(errorGettingFiles(error))
    };
  }
}

export const addFile = createAsyncThunk(
  'fileManager/addFile',
  async ({ req, fileForm }: { req: SubirArchivo, fileForm: any }, { dispatch, rejectWithValue }) => {
    try {
      const tempFile = await requestTempFileUpload();
      await uploadFileAPI(tempFile.url, fileForm);
      await addFileAPI(tempFile.file, req);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const deleteFile = (id: string) => {
  return async (dispatch: any) =>{
    dispatch(startEliminatingFile());
    try {
      await deleteFileAPI(id);
      dispatch(doneEliminatingFile());
    } catch (error) {
      dispatch(errorEliminatingFile());
    }
  }  
} 

export const updateFile = (data: EditarArchivo) => {
  return async (dispatch: any) => {
    dispatch(startEditingFile())
    try {
      await updateFileAPI(data)
      await dispatch(getFiles(data.contractid))
      const [ archivoActualizado ] = await getFileAPI(data.id)
      dispatch(setSelectedFile(archivoActualizado));
      dispatch(successEditingFile())
    } catch (error) {
      dispatch(errorEditingFile())
    }
  }
}

export const getFile = (idFile: string) => {
  return async (dispatch: any) => {
    dispatch(startGettingSelectedFile())
    try {
      const [ archivo ] = await getFileAPI(idFile)
      dispatch(setSelectedFile(archivo))
    } catch (e) {
      dispatch(errorGettingSelectedFile(e))
    }
  }
}