
import axios, { AxiosResponse, RawAxiosRequestHeaders } from "axios";
import { Archivo, ContractIdParams, EditarArchivo, SubirArchivo, TempFileUpload,
	User, Message, MessageDraft, MessageSummary, MessageDraftRequest,
	MessageType} from "src/util/types";
import { clearUser, getUser, setUser } from "./credentialsStore";
import { getFinalHash, hash } from "src/util/files";

export const ERROR_MESSAGES: { [code: number]: string } = {
	0x0001: 'Usuario o contraseña inválido, intente nuevamente',
	0x0002: 'Contraseña incorrecta',
	0x0003 : 'Token inválido',
	0x0004 : 'Contraseña incorrecta',
	0x0005 : 'Usuario bloqueado',
	0x0006 : 'Usuario no encontrado',
	0x0007 : 'No tiene permisos para realizar esta acción',
	0x0008 : 'Contraseña de certificado incorrecta',
};

const API_URL = process.env.REACT_APP_API_URL!;

const client = axios.create({ baseURL: API_URL });

function processResponse(response: AxiosResponse)
{
	if (!response.data.ok)
	{
		// Handle invalid token
		if (response.data.errorCode == 0x03)
		{
			clearUser();
			if (window.location.pathname !== "/login") {
				window.location.href = "/login";
			}
			return;
		}

		const message = ERROR_MESSAGES[response.data.errorCode] ??
			response.data.error;
		throw new Error(message);
	}
	return response.data.data;
}

function generateHeaders(): RawAxiosRequestHeaders
{
	const user = getUser();
	return user?.token ?
		{ token: user.token } : {};
}

async function get<T>(url: string, params?: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.get(url, { params, headers });
	return processResponse(response) as T;
}

async function post<T>(url: string, data: any): Promise<T>
{
	const headers = generateHeaders();
	const response = await client.post(url, data, { headers });
	return processResponse(response) as T;
}

export const login = async (email: string, password: string) => {
	const user = await post<User>('login', { email, password });
	setUser(user);
	return user;
};
export const logout = () => {
	clearUser();
};

export const uploadFile = async (url: string, file: any) =>
{
	const sha = await hash(file)

	await client.put(
		url,
		file,
		{
			headers:
			{
				"Content-Type": file.type,
				"x-amz-checksum-sha256": sha
			}
		});

}
export const requestTempFileUpload = async (file?: any) => {
	let params = null;
	if(file) {
		const hashData = await hash(file)
		params = {
			multipart: true,
			hash: hashData
		}
	}
	return get<TempFileUpload>('requestTempFileUpload', params)
};
export const addFile = (tempFile: string, file: SubirArchivo) => post('addFile', { tempFile, file });
export const updateFile = async (req: EditarArchivo) => post('updateFile', req);
export const deleteFile = async (id: string) => post('deleteFile', { id });
export const getFiles = (params: ContractIdParams) => get<Archivo[]>('getFiles', params);
export const getFile = (id: string) => get<Archivo[]>('getFile', { id });
export const downloadFile = (id: string) => get<string>('downloadFile', { id });
export const getMessages = (contractid: string) => get<MessageSummary[]>('getMessages', { contractid });
export const getMessage = (id: string) => get<Message>('getMessage', { id });
export const getDrafts = (contractid: string) => get<MessageSummary[]>('getDrafts', { contractid });
export const getDraft = (id: string) => get<MessageDraft>('getDraft', { id });
export const saveDraft = (draft: MessageDraftRequest) => post<string>('saveDraft', draft);
export const deleteDraft = (id: string) => post('deleteDraft', { id });
export const sendMessage = (id: string, signaturePassword: string) => post('sendMessage', { id, signaturePassword });
export const saveUserCertificate = (tempFile: string) => post<User>('saveUserCertificate', { tempFile });
export const saveUserSignatureImage = (tempFile: string) => post<User>('saveUserSignatureImage', { tempFile });
export const downloadMessagePdf = (id: string) => get<string>('downloadMessagePdf', { id });
export const downloadMessageAttachment = (id: string) => get<string>('downloadAttachment', { id });
export const getMessageTypes = () => get<MessageType[]>('getMessageTypes');
export const changePassword = (password: string) => post('changePassword', { password });
