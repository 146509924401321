import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Alert, Card, CardBody, Col, Container, Row, Spinner } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { CommActionState } from 'src/util/types';
import { CommDetail } from "./CommDetail";
import { fetchCommunicationById } from 'src/slices/Communications/thunks';
import { RootState } from 'src';
import { useAppDispatch } from 'src/hooks/useAppDispatch';

export const OpenComm = () => {
  const dispatch = useAppDispatch();
  const { selected_comm, selected_comm_msg, selected_comm_status, comm_responses } = useSelector((state: RootState) => state.communications);


  document.title = "Ver comunicación";

  const { id } = useParams();

  useEffect(() => {
    dispatch(fetchCommunicationById(id));
  }, [id]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  {
                    (selected_comm_status === CommActionState.LOADING) &&
                    <Spinner
                      className="spinner-border text-primary mt-4"
                      role="status"
                    ></Spinner>
                  }
                  {
                    (selected_comm_status === CommActionState.ERROR) &&
                    <CardBody>
                      <Alert color="danger">
                        {selected_comm_msg}
                      </Alert>
                    </CardBody>
                  }
                  {
                    (selected_comm) &&
                    <>
                      <CommDetail selectedComm={selected_comm} isResponse={false} ></CommDetail>
                      <Link
                        to={`/comunicaciones/nuevo?parentid=${selected_comm.id}`}
                        className="btn btn-light waves-effect mt-4">
                        <i className="mdi mdi-reply"></i> Responder
                      </Link>
                    </>
                  }
                </CardBody>
              </Card>
              {/* Respuestas */}
              {
                (comm_responses?.length > 0) &&
                <React.Fragment>
                  <h4>Respuestas</h4>
                  <Card>
                    <CardBody>
                      {comm_responses.map((comm) => (
                        <CommDetail key={comm.id} selectedComm={comm} isResponse={true} sequence={selected_comm?.sequence}></CommDetail>
                      ))
                      }
                    </CardBody>
                  </Card>
                </React.Fragment>
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
