import { Alert, Input, Label, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import DOMPurify from 'dompurify';
import React, { useEffect } from "react";

import { CommActionState, MessageSummary } from "src/util/types";
import { fetchCommunications } from "src/slices/Communications/thunks";
import { RootState } from "src";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import EmailToolbar from "./email-toolbar";
import { isoToShortDate } from "src/util/date";



export const CommsAdmin = () => {

  const dispatch = useAppDispatch();

  const { comms_msg, comms_status, communications } = useSelector(
    (state: RootState) => state.communications
  );

  const sortedComms = [...communications].sort((a, b) => {
      const sequenceA = a.sequence === null ? -Infinity : parseInt(a.sequence);
      const sequenceB = b.sequence === null ? -Infinity : parseInt(b.sequence);
      return sequenceB - sequenceA;
  });

  function stripHtmlTags(comm: MessageSummary): string {
    const html = comm.bodysummary;
    const sanitizedHTML = DOMPurify.sanitize(html);
    const div = document.createElement('div');
    if(comm.sequence) {
      div.innerHTML = `Correlativo: ${comm.sequence} - ${sanitizedHTML}`;
    } else {
      div.innerHTML = sanitizedHTML;
    }
    return div.innerText;
  }

  useEffect(() => {
    dispatch(fetchCommunications());
  }, [dispatch]);

  return (

    <React.Fragment>
      <EmailToolbar />
      {
        (comms_status === CommActionState.ERROR) &&
          <Alert color='danger' className="mt-4">
            {comms_msg}
          </Alert>
      }
      {
        (comms_status === CommActionState.LOADING) &&
        <Spinner
          className="spinner-border text-primary mt-4"
          role="status"
        ></Spinner>
      }
      {
        (comms_status === CommActionState.SUCCESS) &&
          <ul className="message-list">
            {
              sortedComms.map((comm) => (
                <li key={comm.id} className={comm.read ? "" : "unread"}>
                  <div className="col-mail col-mail-1">
                    <Link to={`/comunicaciones/abrir/${comm.id}`} className="comm-title">
                      {comm.author} {comm.replies > 0 ? `(${comm.replies})` : ""}
                    </Link>
                  </div>
                  <div className="col-mail col-mail-2">
                    <Link to={`/comunicaciones/abrir/${comm.id}`} className="subject">
                      <span className={classNames("badge", comm.badge.color, "me-2")}>
                        {comm.badge.name}
                      </span>
                      <span className="teaser" dangerouslySetInnerHTML={ { __html: stripHtmlTags(comm) } }>
                      </span>
                    </Link>
                    <div className="date">
                      <Link to={`/comunicaciones/abrir/${comm.id}`}>
                        {isoToShortDate(comm.date)}
                      </Link>
                    </div>
                  </div>
                </li>
              ))
            }
          </ul>
      }

    </React.Fragment>
  );
}
