import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, Row, Spinner } from 'reactstrap';
import { convertToRaw, EditorState } from 'draft-js';
import { FormikProps, useFormik } from 'formik';
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import * as Yup from "yup";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useState } from 'react';

import { ActionState, CommActionState, FormValues, Message, MessageDraft, MessageType, UploadImageResult } from '../../util/types';
import { addDraftComm, deleteDraftComm, getDraftById, updateDraftComm } from "src/slices/Draft Communications/thunks";
import { addNewComm, fetchCommunicationById, validatePassForSignComm } from 'src/slices/Communications/thunks';
import { CommDetail } from "./CommDetail";
import { loadDraft } from "src/util/comms";
import { onChangePassword, resetCommsState, resetCommsStatus } from "src/slices/Communications/reducer";
import { resetDraftState, resetDraftStatus } from "src/slices/Draft Communications/reducer";
import { RootState } from 'src';
import { StatusAlert } from "src/components/Common/StatusAlert";
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useParams, useSearchParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CommunicationForm from "./CommForm";

export const NewComm = () => {
  document.title = "Nueva comunicación";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { new_comm_status, new_comm_msg, validating_status, validating_msg, savedCommId } = useSelector((state: RootState) => state.communications)
  const { draftAddStatus, draftMsg, draftUpdateStatus, draftUpdateMsg, draftDeleteMsg, draftDeleteStatus, messageTypes } = useSelector((state: RootState) => state.draftComms)
  const { user } = useSelector((state: RootState) => state.login)

  const { id = null } = useParams();

  const [ searchParams ] = useSearchParams();
  const parentId = searchParams.get('parentid');
  const [parentMessage, setParentMessage] = useState<Message | null>(null);

  const [signModal, setSignModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [isResponse, setIsResponse] = useState(false)

  const [isDeleting, setIsDeleting] = useState(false)
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [draftStatusLoading, setDraftStatusLoading] = useState<ActionState>(ActionState.NOT_STARTED);
  const [draftLoadingMsg, setDraftLoadingMsg] = useState<string>('');
  const [draftSelected, setDraftSelected] = useState<MessageDraft | null>(null);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  const handleFilesUpload = (value: boolean) => { setIsLoadingFiles(value) } 

  const timeToAct = 1000;

  useEffect( () => {
    dispatch(resetDraftStatus());
    dispatch(resetCommsStatus());
    if(id) {
      getDraft(id);
    }
    if(parentId) {
      setIsResponse(true)
      getParentMessage(parentId);
    }
  }, [id])

  const getParentMessage = async (parentId: string) => {
    await dispatch(fetchCommunicationById(parentId)).then((action) => {
      if(fetchCommunicationById.fulfilled.match(action)) {
        setParentMessage(action.payload)
      }
    })
  }


  useEffect(() => {
    if (validating_status === ActionState.SUCCESS) {
      redirect("/comunicaciones/general")
    }
  }, [validating_status, navigate]);

  useEffect(() => {
    if (draftAddStatus === ActionState.SUCCESS) {
      redirect("/comunicaciones/borradores");
    }
  }, [draftAddStatus, draftUpdateStatus, navigate]);

  const getDraft = async (id: string) => {
    try {
      setDraftStatusLoading(ActionState.LOADING)

      const action = await dispatch(getDraftById(id))
      
      if(getDraftById.fulfilled.match(action)) {
        setDraft(action.payload);
        setDraftStatusLoading(ActionState.SUCCESS);
        setDraftLoadingMsg('Se encuentra editando un borrador');
      }
      if(getDraftById.rejected.match(action)) {
        throw new Error(action.error.message ?? 'Error al cargar el borrador')
      }

    } catch (error: any) {
      setDraftLoadingMsg(error?.message ?? 'Error al cargar el borrador')
      setDraftStatusLoading(ActionState.ERROR)
    }
  }

  const setDraft = (draft: MessageDraft) => {
    setDraftSelected(draft);
    formik.setValues({
      commType: draft.typeid ?? '',
      editorContent: draft.body,
      deadline: draft.deadline ?? '',
      attachedFiles: draft.attachments,
      mustRespond: false
    })
    setEditorState(loadDraft((draft.body ?? '')));
    formik.setFieldTouched("commType", true);   
  }

  const redirect = (url: string) => {
    const timeout = setTimeout(() => {
      dispatch(resetCommsState())
      dispatch(resetDraftState())
      navigate(url);
    }, timeToAct);

    return () => {
      clearTimeout(timeout);
    };
  }

  const handleEditorChange = (state: EditorState) => {
    setEditorState(state);

    const contentAsHtml = draftToHtml(convertToRaw(state.getCurrentContent()));
    formik.setFieldValue("editorContent", contentAsHtml);
  };

  const uploadImageCallback = (file: File): Promise<UploadImageResult> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const validationSchema = Yup.object({
    commType: Yup.lazy( _ => 
      isResponse
        ? Yup.string()
        : Yup.string()
            .required("El tipo de comunicación es requerido")
            .notOneOf([""], "Por favor, selecciona un tipo de comunicación válido")
    ),
    editorContent: Yup.string().test(
      "editorContent",
      "El cuerpo de la comunicación no puede estar vacío.",
      (value = "") => {
        // Eliminar todas las etiquetas HTML
        const stripped = value.replace(/<[^>]*>/g, "").trim();
        return stripped.length > 0;
      }
    )
    ,
    mustRespond: Yup.boolean().when('commType', {
      is: (value: string) => {
        const messageType = messageTypes.find(m => m.id === value)
        return messageType?.requiresresponse
      },
      then: (schema) => schema.notOneOf([false], "El tipo de comunicación seleccionado debe tener respuesta."),
      otherwise: (schema) => schema.required()
    }),
    deadline: Yup.string().when('commType', {
      is: (value: string) => {
        const messageType = messageTypes.find(m => m.id === value)
        return messageType?.requiresdeadline
      },
      then: (schema) => schema.required("Por favor, seleccione una fecha límite."),
    }),
  })

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      commType: "",
      editorContent: "",
      mustRespond: false,
      deadline: "",
      attachedFiles: []
    },
    validationSchema,
    onSubmit: async values => {
      const data: MessageDraft = {
        body: values.editorContent,
        deadline: values.deadline || null,
        attachments: values.attachedFiles,
        typeid: values.commType,
        parentid: parentId
      };
      if(draftSelected?.id) {
        data.id = draftSelected.id;
        dispatch(updateDraftComm(data)) 
      } else { 
        dispatch(addDraftComm(data))
      };
    }
  });

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function toggleSingModal() {
    setSignModal(!signModal);
    removeBodyCss();
  }

  const toggleErrorModal = () => {
    setErrorModal(!errorModal);
    removeBodyCss();
  }

  const continueFlow = () => {
    if(user.hasCertificate && user.hasRubric) {
      toggleSingModal();
    } else {
      toggleErrorModal();
    }
  }

  const saveCommForSign = async () => {
    const data: MessageDraft = {
      body: formik.values.editorContent,
      deadline: (formik.values.deadline) === "" ? null : formik.values.deadline,
      typeid: formik.values.commType,
      attachments: formik.values.attachedFiles,
      parentid: parentId
    };

    // Si hay un borrador seleccionado, lo actualiza 
    if(draftSelected) {
      data.id = draftSelected.id;
      await dispatch(updateDraftComm(data)).then(() => {
          continueFlow();
      });
    }
    // Si no está en una comunicación y tampoco se ha guardado el borrador, se debe guardar
    if(!savedCommId && !draftSelected){
      await dispatch(addNewComm(data)).then(() => {
        continueFlow();
      })
    }
    // Si ya se guardó el borrador, se levanta el modal
    if(new_comm_status === ActionState.SUCCESS) {
      continueFlow();
    }
  }

  const signFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string().required()
    }),
    onSubmit: async ({ password }) => {
      if(draftSelected) {
        dispatch(validatePassForSignComm({ password, commId: draftSelected.id! }));
      } else {
        dispatch(validatePassForSignComm({ password, commId: savedCommId}))
      }
    }
  })

  useEffect(() => {
    dispatch(onChangePassword())
  }, [signFormik.values.password])

  const deleteDraft = () => {
  
    if(!savedCommId && !draftSelected) {
      navigate("/comunicaciones/general")
    }

    if(savedCommId ) {
      dispatch(deleteDraftComm(savedCommId))
    }
    if(draftSelected) {
      dispatch(deleteDraftComm(draftSelected.id!))
    }
  }

  useEffect(() => {
    if(draftDeleteStatus === CommActionState.LOADING) {
      setIsDeleting(true)
    }
    if(draftDeleteStatus === CommActionState.SUCCESS) {
      setIsDeleting(false)
      if (!draftSelected) {
        redirect("/comunicaciones/general");
      } else {
        redirect("/comunicaciones/borradores");
      }
    }
  }, [draftDeleteStatus])
  
  const [deleteDraftModal, setDeleteDraftModal] = useState(false)
  const toggleDeleteDraftModal = () => {
    setDeleteDraftModal(!deleteDraftModal)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Comunicaciones" breadcrumbItem={ isResponse ? "Emitir respuesta a comunicación" : "Emitir nueva comunicación"} />

          {
            (parentMessage) &&
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CommDetail selectedComm={parentMessage} isResponse={false} ></CommDetail>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }

          <Row>
            <Col className="col-12">
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <h4 className="card-title">Ingresa la información correspondiente</h4>
                </CardHeader>
                {
                  (draftStatusLoading === ActionState.LOADING) ?
                    <CardBody>
                      <Spinner
                        className="spinner-border text-primary mt-4"
                        role="status"
                      ></Spinner>
                    </CardBody>
                    :
                    <CardBody>
                      {draftSelected && <Alert fade={false} color="warning" className="mt-1">{draftLoadingMsg}</Alert>}

                      <CommunicationForm
                        editorState={editorState}
                        handleEditorChange={handleEditorChange}
                        uploadImageCallback={uploadImageCallback}
                        onSubmit={formik.handleSubmit}
                        formik={formik} 
                        handleFilesUpload={handleFilesUpload}
                        isLoadingFiles={isLoadingFiles}
                        isResponse={isResponse}
                      />
                      <Row>
                        <Col className="col-12 d-flex">
                          <Button
                            color="primary"
                            className="me-2"
                            type="button"
                            onClick={ async () => {
                              await formik.setFieldTouched("commType", true);
                              await formik.setFieldTouched("editorContent", true);
                              
                              const errors = await formik.validateForm();

                              if (Object.keys(errors).length === 0) {
                                saveCommForSign();
                              }
                            }}
                            data-toggle="modal"
                            disabled={!formik.isValid || !formik.dirty || formik.isSubmitting || isDeleting || isLoadingFiles}
                          >
                            Emitir comunicación
                          </Button>
                          <Button
                            color="secondary"
                            className="me-2"
                            type="button"
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting || isDeleting || isLoadingFiles}
                          >
                            Guardar borrador
                          </Button>
                          <Button
                            color="danger"
                            className="ms-auto"
                            type="button"
                            onClick={() => toggleDeleteDraftModal()}
                            disabled={isDeleting || isLoadingFiles}
                          >
                            <i className="mdi mdi-delete me-2"></i>Eliminar borrador
                          </Button>
                        </Col>
                      </Row>

                      <StatusAlert status={draftAddStatus} msg={draftMsg} progressBar={true} progressTime={timeToAct}></StatusAlert>

                      <StatusAlert status={draftUpdateStatus} msg={draftUpdateMsg}></StatusAlert>

                      <StatusAlert status={new_comm_status} msg={new_comm_msg}></StatusAlert>

                      <StatusAlert status={draftDeleteStatus} msg={draftDeleteMsg} progressBar={true} progressTime={timeToAct}></StatusAlert>

                    </CardBody>
                }
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Sign modal */}
      <Modal
        isOpen={signModal}
        toggle={() => {
          toggleSingModal();
        }}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Firmar comunicación</h5>
          <button
            type="button"
            onClick={() => { setSignModal(false); }}
            className="close"
            data-dismiss="modal"
            aria-label="Cerrar modal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form 
            autoComplete="new-password"
            onSubmit={e => {
              e.preventDefault();
              return; }
            }
            >
            <Row className="my-4"> {/* password */}
              <Label htmlFor="password-input" className="col-sm-3 col-form-label">Ingrese su clave de Firma Eléctronica</Label>
              <Col sm={9}>
                <Input
                  autoComplete="new-password"
                  name="password"
                  placeholder="Clave de Firma Eléctronica"
                  type="password"
                  className="form-control"
                  id="password-input"
                  onChange={signFormik.handleChange}
                  onBlur={signFormik.handleBlur}
                  value={signFormik.values.password || ""}
                  invalid={
                    signFormik.touched.password &&
                      signFormik.errors.password
                      ? true
                      : false
                  }
                />
                { signFormik.touched.password && signFormik.errors.password ? 
                  <FormFeedback type="invalid">
                    {signFormik.errors.password}
                  </FormFeedback>
                  : null
                }
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button
                  color="success"
                  className="me-2"
                  type="submit"
                  onClick={signFormik.submitForm}
                >
                  Firmar comunicación
                </Button>
              </Col>
            </Row>
          </Form>

          <StatusAlert status={validating_status} msg={validating_msg} progressBar={true} progressTime={timeToAct}></StatusAlert>

        </div>
      </Modal>

      {/* Error modal */}
      <Modal
        isOpen={errorModal}
        toggle={() => {
          toggleErrorModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <div>
            <div className="alert alert-danger mb-0 d-flex flex-column" role="alert">
              <h4 className="alert-heading"><i className="mdi mdi-alert-circle-outline me-2"></i>No es posible firmar la comunicación</h4>

              <p className="p-0">
                No cuenta con los siguientes archivos necesarios para realizar la firma de la comunicación:
                <ul className="mt-4">
                  {!user.hasCertificate && <li>Certificado de Firma Digital</li>}
                  {!user.hasRubric && <li>Rúbrica</li>}
                </ul>
                La comunicación fue guardada como borrador por lo que puede continuar la edición si lo desea.
              </p>
              <hr />
              <p className="mb-0">
                Para cargar los archivos necesarios debe ir a su <strong>Perfil</strong>.
              </p>
              <Row>
                <Button className="btn btn-dark mt-4 mx-auto" style={{ width: '35%' }} onClick={()=> navigate("/profile")} >Ir al Perfil</Button>
                <Button className="btn btn-secondary mt-4 mx-auto" style={{ width: '35%' }} onClick={toggleErrorModal}>Cerrar</Button>

              </Row>
            </div>
          </div>

        </div>
      </Modal>
      
      <Modal
        isOpen={deleteDraftModal}
        toggle={() => {
          toggleDeleteDraftModal();
        }}
        centered={true}
      >
        <div className="modal-body">
          <div className="text-center">
            <h5>¿Esta seguro de eliminar el borrador?</h5>
            <div className="mt-4">
              <Button
                color="danger"
                className="me-2"
                onClick={() => {
                  deleteDraft();
                  toggleDeleteDraftModal();
                  }
                }
              >
                <i className="mdi mdi-delete me-2"></i>Sí
              </Button>
              <Button
                color="secondary"
                onClick={() => toggleDeleteDraftModal()}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
